<template>
  <div data-layout="auth/auth" class="bg-forgd-bgd-500 min-h-screen font-normal text-forgd-primary-900 pt-4 bg-pattern">
    <div class="max-w-forgd mx-auto px-6 flex flex-col items-center justify-center py-14">
      <svg xmlns="http://www.w3.org/2000/svg" width="239" height="54" viewBox="0 0 239 54" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M113.302 30.0483C110.871 30.0483 108.661 29.6758 106.673 28.9308C104.712 28.1858 102.985 27.1372 101.494 25.7852C100.03 24.4056 98.8972 22.8052 98.0962 20.9841C97.2951 19.163 96.8946 17.1763 96.8946 15.0241C96.8946 12.8719 97.2951 10.8853 98.0962 9.06415C98.8972 7.24304 100.03 5.65647 101.494 4.30443C102.985 2.92481 104.712 1.86249 106.673 1.1175C108.661 0.372499 110.857 0 113.261 0C115.636 0 117.818 0.372499 119.807 1.1175C121.796 1.86249 123.522 2.92481 124.986 4.30443C126.45 5.65647 127.582 7.24304 128.383 9.06415C129.184 10.8853 129.585 12.8719 129.585 15.0241C129.585 17.1488 129.184 19.1354 128.383 20.9841C127.582 22.8052 126.45 24.4056 124.986 25.7852C123.522 27.1372 121.796 28.1858 119.807 28.9308C117.818 29.6758 115.65 30.0483 113.302 30.0483ZM113.261 23.4261C114.586 23.4261 115.802 23.2191 116.907 22.8052C118.039 22.3913 119.02 21.8119 119.848 21.0669C120.677 20.2943 121.312 19.3975 121.754 18.3766C122.196 17.3557 122.417 16.2382 122.417 15.0241C122.417 13.8101 122.196 12.6926 121.754 11.6716C121.312 10.6507 120.677 9.76775 119.848 9.02276C119.02 8.25016 118.039 7.65693 116.907 7.24304C115.802 6.82915 114.586 6.6222 113.261 6.6222C111.935 6.6222 110.706 6.82915 109.573 7.24304C108.468 7.65693 107.488 8.25016 106.631 9.02276C105.803 9.79535 105.167 10.6921 104.725 11.713C104.311 12.7339 104.104 13.8377 104.104 15.0241C104.104 16.2382 104.311 17.3557 104.725 18.3766C105.167 19.3975 105.803 20.2943 106.631 21.0669C107.488 21.8119 108.468 22.3913 109.573 22.8052C110.706 23.2191 111.935 23.4261 113.261 23.4261Z" fill="#001221"/>
        <path d="M185.967 29.9655C183.564 29.9655 181.368 29.6206 179.379 28.9308C177.418 28.241 175.719 27.2476 174.283 25.9508C172.846 24.6263 171.728 23.0398 170.927 21.1911C170.153 19.3148 169.766 17.2315 169.766 14.9414C169.766 12.7891 170.167 10.8163 170.968 9.02276C171.797 7.20165 172.971 5.61508 174.49 4.26305C176.009 2.91101 177.791 1.8625 179.835 1.1175C181.906 0.372499 184.157 0 186.588 0C188.439 0 190.151 0.22074 191.726 0.662222C193.3 1.1037 194.709 1.68314 195.952 2.40055C197.223 3.11796 198.272 3.91814 199.101 4.8011L194.833 9.39526C194.087 8.76063 193.286 8.22257 192.43 7.7811C191.601 7.31202 190.69 6.93952 189.696 6.6636C188.729 6.36008 187.679 6.20832 186.547 6.20832C185.193 6.20832 183.936 6.42906 182.776 6.87054C181.644 7.31202 180.649 7.93286 179.793 8.73304C178.937 9.50563 178.274 10.43 177.804 11.5061C177.335 12.5546 177.1 13.6997 177.1 14.9414C177.1 16.3486 177.349 17.604 177.846 18.7077C178.343 19.7838 179.02 20.6944 179.876 21.4394C180.76 22.1844 181.782 22.7638 182.942 23.1777C184.102 23.564 185.345 23.7572 186.671 23.7572C187.748 23.7572 188.756 23.6192 189.696 23.3433C190.662 23.0674 191.505 22.6949 192.223 22.2258C192.941 21.7567 193.48 21.1911 193.839 20.5288C194.128 20.0139 194.316 19.4758 194.405 18.9147H185.967V13.4514H201.09C201.145 13.8101 201.186 14.2378 201.214 14.7344C201.241 15.2311 201.255 15.7002 201.255 16.1416C201.283 16.5831 201.297 16.9004 201.297 17.0936C201.297 19.0802 200.91 20.8737 200.137 22.4741C199.391 24.0469 198.341 25.3851 196.988 26.4888C195.634 27.5925 194.018 28.4479 192.14 29.0549C190.262 29.662 188.204 29.9655 185.967 29.9655Z" fill="#001221"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M209.343 29.6391V0.666982H222.808C225.156 0.666982 227.283 1.03948 229.189 1.78448C231.095 2.50189 232.724 3.52281 234.078 4.84725C235.459 6.17169 236.509 7.71688 237.227 9.4828C237.945 11.2211 238.304 13.1112 238.304 15.1531C238.304 17.2225 237.945 19.1402 237.227 20.9061C236.509 22.672 235.459 24.2034 234.078 25.5003C232.724 26.7971 231.095 27.818 229.189 28.563C227.283 29.2804 225.156 29.6391 222.808 29.6391H209.343ZM222.601 23.4308H216.386V6.8753H222.601C223.982 6.8753 225.197 7.09604 226.247 7.53752C227.297 7.95141 228.167 8.53085 228.857 9.27585C229.576 10.0209 230.128 10.9038 230.515 11.9247C230.901 12.9181 231.095 13.9942 231.095 15.1531C231.095 16.3395 230.901 17.4294 230.515 18.4228C230.128 19.4161 229.576 20.2853 228.857 21.0303C228.167 21.7753 227.297 22.3685 226.247 22.81C225.197 23.2239 223.982 23.4308 222.601 23.4308Z" fill="#001221"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M137.806 0.666982V29.6391H144.518V19.5403H149.491L155.954 29.6391H164.075L156.898 18.6268C157.126 18.5258 157.35 18.4164 157.57 18.2986C159.144 17.4432 160.373 16.2982 161.257 14.8633C162.169 13.4009 162.625 11.7454 162.625 9.89668C162.625 8.07558 162.196 6.47521 161.34 5.09558C160.484 3.71596 159.282 2.63985 157.735 1.86726C156.189 1.06707 154.393 0.666982 152.349 0.666982H137.806ZM151.645 14.1597H144.518V6.54419H151.231C152.114 6.54419 152.874 6.69595 153.509 6.99947C154.172 7.30299 154.683 7.74447 155.042 8.32391C155.429 8.87576 155.622 9.52418 155.622 10.2692C155.622 11.0418 155.443 11.7178 155.084 12.2972C154.752 12.8767 154.283 13.332 153.675 13.6631C153.095 13.9942 152.418 14.1597 151.645 14.1597Z" fill="#001221"/>
        <path d="M68.6983 0.704321V29.6765H75.7419V18.7498H88.9589V12.8726H75.7419V6.91264H90.6577V0.704321H68.6983Z" fill="#001221"/>
        <path d="M12.1263 27.2323L23.4687 0.717182H52.4989L49.4834 7.69634C49.0314 8.74258 48.1856 9.53644 47.0807 9.95163L29.6605 16.4978C27.9588 17.1373 28.4447 19.6582 30.4101 20.387L32.186 21.0455C33.065 21.3715 33.5918 22.3256 33.2949 23.0541L31.1304 28.3662C30.8781 28.9854 30.2712 29.3629 29.5285 29.3629H13.794C12.6743 29.3629 11.7306 28.1573 12.1263 27.2323Z" fill="#001221"/>
        <path d="M0.522681 5.6881C-1.64649 10.4865 3.26832 16.8566 9.13972 16.8566H10.1273C10.3839 16.8566 10.5948 16.7286 10.6867 16.5173L15.3917 5.6881H0.522681Z" fill="#001221"/>
      </svg>
      <slot />
    </div>
  </div>
</template>

<style scoped>
  .bg-pattern {
    background-image: url('/patterns/prelogin.svg');
  }
</style>
<script setup lang="ts">
</script>
